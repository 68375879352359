import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Image from "react-bootstrap/Image";
import logo from '../assets/images/lms.png'
import "../../App.css";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import HomeIcon from "@material-ui/icons/Home";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import HelpIcon from "@material-ui/icons/Help";
import MoreIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "axios";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { logout } from "../../misc/logout";
import Logindialog from "../../misc/logindialog";
import { Base64 } from "js-base64";
import IdleTimer from "../../misc/IdleTimer";
import useFetchUserCountry from "./useFetchUserCountry";
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  custom2: {
    marginRight: "2%",
    display: "block",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    fontWeight: 600,
    color: "#000000",
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      position: "absolute",
      right: "0",
    },
    [theme.breakpoints.up("lg")]: {
      position: "absolute",
      right: "0",
    },
  },
  sectionMobile: {
    display: "flex",
    position: "absolute",
    right: 0,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function HeaderSection({ userCountry }) {
  const [subjects, setSubjects] = React.useState([]);
  const getMenu = () => {
    if (localStorage.getItem("isLogin") == "true") {
      var data = JSON.stringify({
        region_id: localStorage.getItem("access_region"),
        ip_region: "",
      });
    } else {
      var data = JSON.stringify({
        region_id: "",
        ip_region: userCountry,
      });
    }
    if (
      userCountry != "" ||
      (localStorage.getItem("isLogin") == "true" &&
        localStorage.getItem("region") != "")
    ) {
      try {
        axios
          .post(window.base_url + "user/class/subject_list", data, {
            headers: window.header,
          })
          .then(function (response) {
            if (response?.data.status == -1) {
            }
            if (response?.data.status == 1) {
              setSubjects(response?.data?.data);
            }
            return JSON.stringify(response);
          })
          .then(function (parsedData) {})
          .catch((error) => {
            console.log(error);
          });
      } catch (e) {
        console.error(e);
        console.log("error occured in api");
      }
    }
  };
  useEffect(() => {
    getMenu();
  }, [userCountry]);
  const location = useLocation();

  var hours = 1;

  // Session logout timeout
  const [isTimeout, setIsTimeout] = useState(false);

  useEffect(() => {
    const timer = new IdleTimer({
      timeout: 300, //expire after 5 min
      onTimeout: () => {
        setIsTimeout(true);
      },
      onExpired: () => {
        setIsTimeout(true);
      },
    });
    return () => {
      timer.cleanUp();
    };
  }, []);

  
  useEffect(() => {
    if (localStorage.getItem("isLogin") === "true") {
      if (location.pathname !== "/zoom") {
        if (isTimeout) {
          logout();
        }
      }
    }
  }, [isTimeout, location]);
  
  const classes = useStyles();
  const anchorRef = React.useRef(null);
  const anchorRef2 = React.useRef(null);
  const anchorRef3 = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [showLogin, setLoginShow] = React.useState(false);
  const handleLoginClose = () => {
    setLoginShow(false);
  };
  const handleLoginShow = () => {
    setLoginShow(true);
  };
  const [showlogin2, setshowlogin2] = React.useState(false);
  const handleDigitalLearningMenuOpen = (event) => {
    setOpen((prevOpen) => !prevOpen);
    setAnchorEl(event.currentTarget);
  };
  const handleDigitalClassMenuOpen = (event) => {
    setOpen1((prevOpen) => !prevOpen);
  };
  const handleLogoutMenuOpen = (event) => {
    setOpen2((prevOpen) => !prevOpen);
  };
  const handleDigitalLearningMenuClose = (event) => {
    setAnchorEl(null);
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const handleLogoutMenuClose = (event) => {
    setAnchorEl(null);
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const [alignment, setAlignment] = useState("left");
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const menuId = "primary-search-account-menu";
  // digital learning menu
  const renderMenu = (
    <Popper
      onMouseEnter={() => {
        setOpen(true);
        setOpen1(false);
        setOpen2(false);
      }}
      onMouseLeave={() => setOpen(false)}
      style={{ zIndex: "2" }}
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleDigitalLearningMenuClose}>
              <MenuList
                autoFocusItem={open}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
              >
                {subjects.map((item, k) => {
                  return (
                    <MenuItem onClick={handleDigitalLearningMenuClose}>
                      <a href={"/classes?subject=" + item.id}>{item.title}</a>
                    </MenuItem>
                  );
                })}
                <MenuItem onClick={handleDigitalLearningMenuClose}>
                  <Link to="/digitallearning?educationalvideos=true">
                    Educational Videos
                  </Link>
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
  // digital class
  const renderClassMenu = (
    <Popper
      onMouseEnter={() => {
        setOpen1(true);
        setOpen(false);
        setOpen2(false);
      }}
      onMouseLeave={() => setOpen1(false)}
      style={{ zIndex: "999" }}
      open={open1}
      anchorEl={anchorRef2.current}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleDigitalLearningMenuClose}>
              <MenuList
                autoFocusItem={open1}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
              >
                <MenuItem onClick={handleDigitalLearningMenuClose}>
                  <Link to="/liveclass">Live Class</Link>
                </MenuItem>
                {localStorage.getItem("school") == "1" ? (
                  <MenuItem onClick={handleDigitalLearningMenuClose}>
                    <Link to="/school_liveclass">School Live Class</Link>
                  </MenuItem>
                ) : null}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
  // if login
  const renderLogoutMenu = (
    <Popper
      onMouseEnter={() => {
        setOpen2(true);
        setOpen1(false);
        setOpen(false);
      }}
      onMouseLeave={() => setOpen2(false)}
      style={{ zIndex: "2" }}
      open={open2}
      anchorEl={anchorRef3.current}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleLogoutMenuClose}>
              <MenuList
                autoFocusItem={open1}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
              >
                <MenuItem
                  onClick={handleLogoutMenuClose}
                  style={{ textTransform: "capitalize" }}
                >
                  <b>Hi ! {localStorage.getItem("username")}</b>
                </MenuItem>
                {localStorage.getItem("userType") == "1" ? (
                  <MenuItem onClick={handleLogoutMenuClose}>
                    <Link onClick={() => setshowlogin2(true)}>Switch User</Link>
                  </MenuItem>
                ) : (
                  ""
                )}
                {localStorage.getItem("isLogin") &&
                localStorage.getItem("parent_usertype") ? (
                  localStorage.getItem("userType") == "1" &&
                  Base64.decode(localStorage.getItem("parent_usertype")) ==
                    "parent" ? (
                    <MenuItem onClick={handleLogoutMenuClose}>
                      <Link to="/dashboard">Dashboard</Link>
                    </MenuItem>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {localStorage.getItem("isLogin") &&
                localStorage.getItem("parent_usertype") ? (
                  localStorage.getItem("userType") == "1" &&
                  Base64.decode(localStorage.getItem("parent_usertype")) ==
                    "kid" ? (
                    <MenuItem onClick={handleLogoutMenuClose}>
                      <Link to="/student/dashboard">Dashboard</Link>
                    </MenuItem>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {localStorage.getItem("userType") == "2" ? (
                  <MenuItem onClick={handleLogoutMenuClose}>
                    <Link to="/dashboard">Dashboard</Link>
                  </MenuItem>
                ) : (
                  ""
                )}
                {localStorage.getItem("isLogin") &&
                localStorage.getItem("parent_usertype") ? (
                  localStorage.getItem("userType") == "1" &&
                  Base64.decode(localStorage.getItem("parent_usertype")) ==
                    "parent" &&
                  localStorage.getItem("school") != 1 ? (
                    <MenuItem onClick={handleLogoutMenuClose}>
                      <Link to="/profile">Profile</Link>
                    </MenuItem>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {localStorage.getItem("isLogin") &&
                localStorage.getItem("parent_usertype") ? (
                  localStorage.getItem("userType") == "1" &&
                  Base64.decode(localStorage.getItem("parent_usertype")) ==
                    "parent" &&
                  localStorage.getItem("school") == 1 ? (
                    <MenuItem onClick={handleLogoutMenuClose}>
                      <Link to="/student_profile">Profile</Link>
                    </MenuItem>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {localStorage.getItem("userType") == "2" ? (
                  <MenuItem onClick={handleLogoutMenuClose}>
                    <Link to="/instructor/profile">Profile</Link>
                  </MenuItem>
                ) : (
                  ""
                )}
                {localStorage.getItem("isLogin") &&
                localStorage.getItem("parent_usertype") ? (
                  localStorage.getItem("userType") == "1" &&
                  Base64.decode(localStorage.getItem("parent_usertype")) ==
                    "parent" ? (
                    <MenuItem onClick={handleLogoutMenuClose}>
                      <Link to="/parent_payments">Payments</Link>
                    </MenuItem>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {localStorage.getItem("userType") == "2" &&
                localStorage.getItem("school") != "1" ? (
                  <MenuItem onClick={handleLogoutMenuClose}>
                    <Link to="/instructor_payments">Payments</Link>
                  </MenuItem>
                ) : (
                  ""
                )}
                <Divider />
                <MenuItem onClick={handleLogoutMenuClose}>
                  <Link onClick={() => logout()}>Logout</Link>
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      className="pr-5"
    >
      {localStorage.getItem("school") == "1" ? (
        <MenuItem>
          <IconButton aria-label="show 11 new notifications" color="inherit">
            <HomeIcon />
          </IconButton>
          <p>
            <Link
              to={"/school/" + localStorage.getItem("school_url")}
              style={{ color: "black", fontWeight: "900" }}
            >
              Home
            </Link>
          </p>
        </MenuItem>
      ) : null}
      <MenuItem >
        <p style={{ margin: 0 }}>
          <Accordion style={{ boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ padding: 0, height: "0px" }}
            >
              <IconButton
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
              >
                <LocalLibraryIcon />
              </IconButton>

              <Typography
                className={classes.heading}
                style={{ paddingTop: "20px", paddingBottom: "20px" }}
              >
                <Link style={{ color: "black", fontWeight: "600" }}>
                  Digital Learning
                </Link>
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0, margin: "auto", top: 0 }}>
              <List
                style={{ padding: 0, margin: "auto" }}
                component="nav"
                aria-label="main mailbox folders"
              >
                {subjects.map((item, k) => {
                  return (
                    <ListItem button>
                      <a
                        href={"/classes?subject=" + item.id}
                        style={{ color: "black", fontWeight: "900" }}
                      >
                        <ListItemText primary={item.title} />
                      </a>
                    </ListItem>
                  );
                })}
                <ListItem button>
                  <Link
                    to="/digitallearning?educationalvideos=true"
                    style={{ color: "black", fontWeight: "900" }}
                  >
                    <ListItemText primary="Educational Videos" />
                  </Link>
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        </p>
      </MenuItem>
      <MenuItem>
        <p style={{ margin: 0 }}>
          <Accordion style={{ boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ padding: 0, height: "0px" }}
            >
              <IconButton
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
              >
                <AccountCircle />
              </IconButton>

              <Typography
                className={classes.heading}
                style={{ paddingTop: "10px" }}
              >
                <Link style={{ color: "black", fontWeight: "600" }}>
                  Digital Class
                </Link>
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0 }}>
              <List
                style={{ padding: 0, margin: "auto" }}
                component="nav"
                aria-label="main mailbox folders"
              >
                <ListItem button>
                  <Link
                    to="/liveclass"
                    style={{ color: "black", fontWeight: "900" }}
                  >
                    <ListItemText primary="Live Class" />
                  </Link>
                  {localStorage.getItem("school") == 1 ? (
                    <Link
                      to="/school_liveclass"
                      style={{ color: "black", fontWeight: "900" }}
                    >
                      <ListItemText primary="School Live Class" />
                    </Link>
                  ) : (
                    ""
                  )}
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        </p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <HelpIcon />
        </IconButton>
        <p>
          <Link to="/help" style={{ color: "black", fontWeight: "600" }}>
            Support
          </Link>
        </p>
      </MenuItem>
      {!localStorage.getItem("isLogin") ||
      localStorage.getItem("isLogin") == "false" ? (
        <MenuItem>
          <IconButton aria-label="show 11 new notifications" color="inherit">
            <LockOpenIcon />
          </IconButton>
          <p>
            <Link
              style={{ color: "black", fontWeight: "600" }}
              onClick={handleLoginShow}
            >
              Login
            </Link>
          </p>
        </MenuItem>
      ) : (
        <MenuItem>
          <p style={{ margin: 0 }}>
            <Accordion style={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ padding: 0, height: "0px" }}
              >
                <IconButton
                  aria-label="account of current user"
                  aria-controls="primary-search-account-menu"
                  aria-haspopup="true"
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>

                <Typography
                  className={classes.heading}
                  style={{ paddingTop: "10px" }}
                >
                  <Link
                    style={{
                      color: "black",
                      fontWeight: "900",
                      textTransform: "capitalize",
                    }}
                  >
                    {localStorage.getItem("username") != ""
                      ? localStorage.getItem("username")
                      : "Account"}
                  </Link>
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0 }}>
                <List
                  style={{ padding: 0, margin: "auto" }}
                  component="nav"
                  aria-label="main mailbox folders"
                >
                  {localStorage.getItem("userType") == "1" ? (
                    <ListItem button>
                      <Link
                        onClick={() => setshowlogin2(true)}
                        style={{ color: "black", fontWeight: "900" }}
                      >
                        <ListItemText primary="Switch User" />
                      </Link>
                    </ListItem>
                  ) : (
                    ""
                  )}
                  {localStorage.getItem("isLogin") &&
                  localStorage.getItem("parent_usertype") ? (
                    localStorage.getItem("userType") == "1" &&
                    Base64.decode(localStorage.getItem("parent_usertype")) ==
                      "parent" ? (
                      <ListItem button>
                        <Link
                          to="/dashboard"
                          style={{ color: "black", fontWeight: "900" }}
                        >
                          <ListItemText primary="Dashboard" />
                        </Link>
                      </ListItem>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {localStorage.getItem("isLogin") &&
                  localStorage.getItem("parent_usertype") ? (
                    localStorage.getItem("userType") == "1" &&
                    Base64.decode(localStorage.getItem("parent_usertype")) ==
                      "kid" ? (
                      <ListItem button>
                        <Link
                          to="/student/dashboard"
                          style={{ color: "black", fontWeight: "900" }}
                        >
                          <ListItemText primary="Dashboard" />
                        </Link>
                      </ListItem>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {localStorage.getItem("userType") == "2" ? (
                    <ListItem button>
                      <Link
                        to="/dashboard"
                        style={{ color: "black", fontWeight: "900" }}
                      >
                        <ListItemText primary="Dashboard" />
                      </Link>
                    </ListItem>
                  ) : (
                    ""
                  )}
                  {localStorage.getItem("isLogin") &&
                  localStorage.getItem("parent_usertype") ? (
                    localStorage.getItem("userType") == "1" &&
                    Base64.decode(localStorage.getItem("parent_usertype")) ==
                      "parent" &&
                    localStorage.getItem("school") != 1 ? (
                      <ListItem button>
                        <Link
                          to="/profile"
                          style={{ color: "black", fontWeight: "900" }}
                        >
                          <ListItemText primary="Profile" />
                        </Link>
                      </ListItem>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {localStorage.getItem("isLogin") &&
                  localStorage.getItem("parent_usertype") ? (
                    localStorage.getItem("userType") == "1" &&
                    Base64.decode(localStorage.getItem("parent_usertype")) ==
                      "parent" &&
                    localStorage.getItem("school") == 1 ? (
                      <ListItem button>
                        <Link
                          to="/student_profile"
                          style={{ color: "black", fontWeight: "900" }}
                        >
                          <ListItemText primary="Profile" />
                        </Link>
                      </ListItem>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {localStorage.getItem("userType") == "2" ? (
                    <ListItem button>
                      <Link
                        to="/instructor/profile"
                        style={{ color: "black", fontWeight: "900" }}
                      >
                        <ListItemText primary="Profile" />
                      </Link>
                    </ListItem>
                  ) : (
                    ""
                  )}
                  {localStorage.getItem("isLogin") &&
                  localStorage.getItem("parent_usertype") ? (
                    localStorage.getItem("userType") == "1" &&
                    Base64.decode(localStorage.getItem("parent_usertype")) ==
                      "parent" ? (
                      <ListItem button>
                        <Link
                          to="/parent_payments"
                          style={{ color: "black", fontWeight: "900" }}
                        >
                          <ListItemText primary="Payments" />
                        </Link>
                      </ListItem>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {localStorage.getItem("userType") == "2" ? (
                    <ListItem button>
                      <Link
                        to="/instructor_payments"
                        style={{ color: "black", fontWeight: "900" }}
                      >
                        <ListItemText primary="Payments" />
                      </Link>
                    </ListItem>
                  ) : (
                    ""
                  )}
                  <ListItem button>
                    <Link
                      onClick={() => logout()}
                      style={{ color: "black", fontWeight: "900" }}
                    >
                      <ListItemText primary="Logout" />
                    </Link>
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
          </p>
        </MenuItem>
      )}
    </Menu>
  );
  // mobile view

  return (
    <div
      style={{
        display: location.pathname.includes("digitallearningapp")
          ? "none"
          : "flex",
        boxShadow: "0px 1px 4px 1px #00000012",
        zIndex: 10,
        position: "fixed",
        width: "100%",
        backgroundColor: "white",
        height: "85px",
      }}
      className={`${classes.grow} mainHeader`}
      onMouseLeave={() => {
        setOpen(false);
        setOpen1(false);
        setOpen2(false);
      }}
    >
      <AppBar
        className=""
        position="static"
        color="transparent"
        style={{ boxShadow: "none" }}
        // style={{maxWidth:"1200px", margin:"auto"}}
      >
        <Toolbar className="header__toolbar">
          <Link to="/">
            <Image
              // src={window.EXT_URL + "website/assets/logo.png"}
              src={logo}           
              width={100}
              height={80}
              alt="Advanced LMS"
            />
          </Link>
          <div className={(classes.grow, classes.sectionDesktop)}>
            {localStorage.getItem("school") == "1" ? (
              <Link to={"/school/" + localStorage.getItem("school_url")}>
                <Button
                  color="primary"
                  className={(classes.menuButton, "text-dark ")}
                  style={{ fontSize: "16px" }}
                >
                  Home
                </Button>
              </Link>
            ) : null}

            <Button
              color="primary"
              className={(classes.menuButton, "text-dark ")}
              ref={anchorRef}
              endIcon={<ArrowDropDownIcon />}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleDigitalLearningMenuOpen}
              onMouseEnter={() => {
                setOpen(true);
                setOpen1(false);
                setOpen2(false);
              }}
              // onMouseLeave={() => setOpen(false)}
              style={{ fontSize: "16px", textTransform: "capitalize" }}
            >
              Digital Learning
            </Button>
            <Button
              color="primary"
              className={(classes.menuButton, "text-dark ")}
              ref={anchorRef2}
              endIcon={<ArrowDropDownIcon />}
              aria-controls={open1 ? "menu-list-grow" : undefined}
              onClick={handleDigitalClassMenuOpen}
              onMouseEnter={() => {
                setOpen1(true);
                setOpen(false);
                setOpen2(false);
              }}
              // onMouseLeave={() => setOpen1(false)}
              style={{ fontSize: "16px", textTransform: "capitalize" }}
            >
              Digital Class
            </Button>
            <Button
              color="primary"
              className={(classes.menuButton, "text-dark")}
            >
              <Link
                to="/help"
                className="text-dark"
                style={{ fontSize: "16px", textTransform: "capitalize" }}
              >
                Support
              </Link>
            </Button>
            {localStorage.getItem("isLogin") == "false" ||
            !localStorage.getItem("isLogin") ? (
            <Button
                color="primary"
                className={(classes.menuButton, "text-dark")}
                onClick={handleLoginShow}
                style={{ fontSize: "16px", textTransform: "capitalize" }}
              >
                Login
            </Button>
            ) : (
              ""
            )}
            
            {localStorage.getItem("isLogin") == "true" ? (
              <Button
                color="primary"
                className={(classes.menuButton, "text-primary")}
                ref={anchorRef3}
                endIcon={<ArrowDropDownIcon />}
                aria-controls={open2 ? "menu-list-grow" : undefined}
                onClick={handleLogoutMenuOpen}
                onMouseEnter={() => {
                  setOpen2(true);
                  setOpen(false);
                  setOpen1(false);
                }}
                // onMouseLeave={() => setOpen2(false)}
                style={{ textTransform: "capitalize", fontSize: "16px" }}
              >
                {localStorage.getItem("username")}
              </Button>
            ) : (
              ""
            )}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      {renderMobileMenu}
      {renderMenu}
      {renderClassMenu}
      {renderLogoutMenu}

      <Logindialog
        handleLoginClose={handleLoginClose}
        showLogin={showLogin}
        setLoginShow={setLoginShow}
        showlogin2={showlogin2}
        setshowlogin2={setshowlogin2}
      />
    </div>
  );
}
