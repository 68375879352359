import React from "react";
import ReactDOM from "react-dom";
import "./App.css";
import "./misc/Custom.css";
import "./index.css";
import App from "./App";
import HttpsRedirect from "react-https-redirect";
import { CookiesProvider } from "react-cookie";

if (window.self === window.top) {
  ReactDOM.render(
    <HttpsRedirect>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </HttpsRedirect>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(<h6>Under attack</h6>, document.getElementById("root"));
}
