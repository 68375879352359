export const APIDataList = ()=>{
  //Google Captcha site key
  window.RECAPTCHA = "6LefU3sjAAAAABICwRfsApENcHrxndpcL3vDyOrQ";
  // window.site_url = 'https://pre-prod-backend.geoskool.com/';
  // google client credentials pre prod

  window.GOOGLE_ID = "793872794526-3kpcvpuncpgf9i4iv5vg3cqfebs9soh5.apps.googleusercontent.com";
  // window.site_url = "https://m2lmscloud.devm2.com/";
   window.site_url=""
  // window.site_url = "https://prod-backend.geoskool.com/";

  if (localStorage.getItem("school") == "1") {
    // window.base_url = "https://m2lmscloud.devm2.com/api/v1/school/";
    window.base_url = ""
    // window.base_url = "https://prod-backend.geoskool.com/admin/api/v1/school/";
  } else {
    // window.base_url = "https://m2lmscloud.devm2.com/api/v1/";
    window.base_url = "";
    // window.base_url = "https://prod-backend.geoskool.com/admin/api/v1/";
  }
  // window.base_url = "https://m2lmscloud.devm2.com/api/v1/";
  window.base_url=""
  // window.base_url = "https://prod-backend.geoskool.com/admin/api/v1/";
  // window.APP_FILE_URL = stores.userStore.useState(state => state.pdfLink);

  window.APP_FILE_URL = "https://d2w5a743kdru9z.cloudfront.net/";
  window.EXT_URL = "https://d3innrz3ldqpda.cloudfront.net/";
  window.FACEBOOK_ID = "352096940324135";

  // window.school_base_url = "https://m2lmscloud.devm2.com/api/v1/school/";
  window.school_base_url = "";
  // window.school_base_url ="https://prod-backend.geoskool.com/admin/api/v1/school/";

  window.session_expiry_msg = "Session Expired, Please Login Again";
  window.header = {
    "content-type": "application/json",
    Accept: "/",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    time_zone: window.jstz?.determine().name(),
  };

  //client zoom api 2
  window.ZOOMAPIKEY = "kcRA11h5T7Sn7x9mFYbnfw";
  window.ZOOMSECRET = "qPIZsZrcL2q4pMVz5Nn9ZuTQjLOjkrW73rir";
}

