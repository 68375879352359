import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
  Container,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import FacebookIcon from "@material-ui/icons/Facebook";
import { Link } from "react-router-dom";
import ReactNotification from "react-notifications-component";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./newlogindialog.css";
import axios from "axios";

// import "./App.css";
const theme = createTheme({
  palette: {
    primary: {
      main: "#3f51b5", // blue
    },
    secondary: {
      main: "#e91e63", // pink
    },
  },
  typography: {
    fontFamily: "Poppins, Arial",
  },
});

const NewLogindialog = (props) => {
  const [showReg3, setRegShow3] = React.useState(false);
  const handleregClose3 = () => setRegShow3(false);

  const handleregShow3 = () => {setRegShow3(true);props.setLoginShow(false)}

  const cleardata = () => {props.handleLoginClose()}

  const [username, setuserName] = useState("")
  const [password, setPassword] = useState("")

  const HandleLogin = () => {
    axios.post(`https://m2lmscloud.devm2.com/api/v1/user/login`, JSON.stringify({
      username: username,
      password: password,
    }))
      .then((res) => {console.log(res)})
      .catch((err) => { console.log(err)})

    // console.log('ds', username, password)
    
    if (username === "" || password === "") {
      alert("Plese Enter user name and password");
    } else {
      if (username == "student@gmail.com") {
        localStorage.setItem("studentloginNew", true);
        localStorage.setItem("parentLoginNew", false);
        cleardata();
        window.location.href = "/student/dashboard";
      } else if (username == "parent@gmail.com") {
        localStorage.setItem("parentLoginNew", true);
        localStorage.setItem("studentloginNew", false);
        window.location.href = "/dashboard";
      } else {
        alert("Invalid Login credentials");
      }
    }
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Dialog
          maxWidth="xs"
          onClose={cleardata}
          open={props.showLogin}
          disableScrollLock={true}
        >
          <DialogTitle onClose={cleardata} sx={{ mt: 2, fontSize: "30px" }}>
            Login
          </DialogTitle>
          <DialogContent sx={{ mt: 2 }}>
            <TextField
              variant="outlined"
              sx={{ mb: 3, mt: 1 }}
              label="Email"
              size="small"
              type="email"
              fullWidth
              name="username"
              value={username}
              onChange={(e) => setuserName(e.target.value)}
            />
            <TextField
              variant="outlined"
              sx={{ mb: 1 }}
              label="Password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              size="small"
              type="password"
              fullWidth
            />
            <FormControlLabel
              control={<Checkbox name="checkedB" color="primary" />}
              label="Remember Me"
            />
            <Button
              fullWidth
              autoFocus
              className="btn-primary mb-2 gradient-button"
              variant="contained"
              sx={{ height: "43px", fontWeight: "500" }}
              onClick={HandleLogin}
            >
              Login
            </Button>
            <Grid container>
              <Grid item xs={5}>
                <hr style={{ margin: "10px 5px" }} />
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "center" }}>
                OR
              </Grid>
              <Grid item xs={5}>
                <hr style={{ margin: "10px 5px" }} />
              </Grid>
            </Grid>
            <GoogleLogin
              clientId="YOUR_GOOGLE_CLIENT_ID"
              buttonText="SignIn with Google"
              cookiePolicy={"single_host_origin"}
              className="google-login-btn mb-2 mt-2"
              theme="dark"
              longtitle={true}
            />
            <FacebookLogin
              appId="YOUR_FACEBOOK_APP_ID"
              autoLoad={false}
              fields="name,email,picture"
              callback={() => {}}
              icon={<FacebookIcon />}
              textButton="SignIn with Facebook"
            />
          </DialogContent>
          <DialogActions>
            <Container sx={{ textAlign: "center" }}>
              <Grid item xs={12}>
                <Typography gutterBottom>
                  <Link
                    color="primary"
                    // component="button"
                    variant="body2"
                    onClick={handleregShow3}
                  >
                    Forgot password?
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom>
                  Not a Member Yet?
                  <Link
                    color="secondary"
                    variant="body1"
                    to="/register-here"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      props.setLoginShow(false);
                    }}
                  >
                    &nbsp;JOIN NOW!
                  </Link>
                </Typography>
              </Grid>
            </Container>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <Dialog
        className="registrationModal"
        id="registrationModal"
        maxWidth="sm"
        fullWidth
        onClose={handleregClose3}
        scroll="body"
        aria-labelledby="customized-dialog-title"
        open={showReg3}
        disableScrollLock
      >
        <DialogTitle
          onClose={handleregClose3}
          style={{ minWidth: "300px", marginTop: 2, fontSize: "25px" }} // Reduced minWidth for better responsiveness
        >
          {"Forgot Password"}
        </DialogTitle>
        <DialogContent>
          <Container>
            <Grid container spacing={2} sx={{ paddingTop: 3 }}>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  type="text"
                  name="resetemail"
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  autoFocus
                  className="csbtn btn-lmsprimary"
                  variant="contained"
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          </Container>
        </DialogContent>
      </Dialog>

      {/* <Dialog
                className="registrationModal"
                id="registrationModal"
                maxWidth="xs"
                disableBackdropClick="true"
                onClose={handlesecondloginClose}
                scroll="body"
                aria-labelledby="customized-dialog-title"
                open={props.showlogin2}
                disableScrollLock={true}
            >
                <ReactNotification />
                <div className="cst">
                    <DialogTitle
                        id="customized-dialog-title"
                        className="nwbtn"
                        onClose={handlesecondloginClose}
                        style={{ minWidth: "360px" }}
                    >
                        Login as
                    </DialogTitle>
                </div>
                <DialogContent dividers>
                    <Container>
                        <Grid container>
                            {parentpasscode ? (
                                <React.Fragment>
                                    <Grid item xs={12} style={{ textAlign: "center" }}>
                                        <TextField
                                            error={loginparentpassworderr}
                                            // id="outlined-error-helper-text"
                                            helperText={
                                                loginparentpassworderr ? "Required Field" : null
                                            }
                                            label="Parent Passcode"
                                            type="password"
                                            value={parentpass}
                                            name="parentpassword"
                                            onChange={handleparentpasscode}
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Link
                                            color="primary"
                                            component="button"
                                            variant="body2"
                                            onClick={handleregShow4}
                                        >
                                            Forget Passcode?
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12} style={{ textAlign: "center" }}>
                                        <Button
                                            className="csbtn btn-lmsprimary"
                                            onClick={checkparent}
                                        >
                                            Continue as Parent
                                        </Button>
                                    </Grid>
                                </React.Fragment>
                            ) : (
                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                    <Button className="csbtn btn-lmsprimary" onClick={parent}>
                                        Continue as Parent
                                    </Button>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Divider />
                                <Typography
                                    style={{
                                        color: "grey",
                                        paddingTop: "15px",
                                        fontSize: "18px",
                                    }}
                                >
                                    Login as Student
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    size="small"
                                    style={{ width: "100%" }}
                                >
                                    <InputLabel id="demo-simple-select-outlined-label">
                                        {" "}
                                        Select Student
                                    </InputLabel>
                                    <Kidselectbox
                                        handlestudent1={handlestudent1}
                                        studentdetails1={studentdetails1}
                                        studenterror={studenterror}
                                    />
                                    <FormHelperText style={{ color: "red" }}>
                                        {studenterror ? "Required Field" : ""}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            {ifpassword ? (
                                <Grid item xs={12}>
                                    <TextField
                                        error={loginpassworderror}
                                        // id="outlined-error-helper-text"
                                        defaultvalue={studentdetails2}
                                        helperText={loginpassworderror ? "Required Field" : null}
                                        label="passcode"
                                        type="password"
                                        name="stpassword"
                                        onChange={handlestudent2}
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                            ) : null}
                            <Grid item xs={12}>
                                <div
                                    className={`alert ${isSuccess3 ? "alert-success" : "alert-danger"
                                        } ${isShowingAlert3 ? "alert-shown" : "alert-hidden"}`}
                                    onTransitionEnd={() => setShowingAlert3(false)}
                                >
                                    <strong>{showAlertMsg3}</strong>
                                </div>
                                <Button
                                    fullWidth
                                    autoFocus
                                    onClick={handlestudentLogin}
                                    className="csbtn btn-lmsprimary"
                                    variant="contained"
                                >
                                    Login
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>
            </Dialog> */}
    </div>
  );
};

export default NewLogindialog;
