import axios from "axios";
import { useEffect, useState } from "react";

const useFetchUserCountry = () => {
    const [userCountry, setuserCountry] = useState("");
    const [ipAddress, setIpAddress] = useState("");
    const [loading, setLoading] = useState(true);
    const getusercountry = async () => {
        try {
          setLoading(true)
         const response = await axios.get("https://ipapi.co/json/")
          setuserCountry(response?.data.country)
          setIpAddress(response?.data.ip)
          setLoading(false)
        } catch (e) {
          console.error(e);
          console.log("error occured in api");
          setLoading(false)
        }
      };
      useEffect(() => {
        if (localStorage.getItem("isLogin")) {
          if (localStorage.getItem("isLogin") == "true") {
            setuserCountry(localStorage.getItem("region"));
          } else {
            getusercountry();
          }
        } else {
          getusercountry();
        }
      }, []);
    return {userCountry, setuserCountry, ipAddress, loading}
}

export default useFetchUserCountry
