export const logout = () => {
    localStorage.setItem('isLogin', false);
    localStorage.setItem('username', '');
    localStorage.setItem('userid', '');
    localStorage.setItem('token', '');
    localStorage.setItem('userType', '');
    localStorage.setItem('kid', '');
    localStorage.setItem('parent_usertype', '');
    localStorage.setItem('school', 0);
    localStorage.setItem('region', "");
    localStorage.setItem('access_region', "");
    setTimeout(() => {
        window.location.href = '/';
    }, 500);
}