import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import { Add, PlusOne } from "@material-ui/icons";
import { logout } from "../../misc/logout";
import { Base64 } from "js-base64";
import IdleTimer from "../../misc/IdleTimer";
import Logindialog from "../../misc/logindialog";
import logo from "../assets/newImages/simple-logo--illustration--lms--elearning-platform--education--minimalist 1.png";
import "./NewHeader.css";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Placeholder,
} from "reactstrap";

function NewHeader({ userCountry }) {
  const [subjects, setSubjects] = useState([]);

  const [showLogin, setLoginShow] = React.useState(false);
  const [showlogin2, setshowlogin2] = React.useState(false);

  const handleLoginShow = () => {
    setLoginShow(true);
  };

  const handleLoginClose = () => {
    setLoginShow(false);
  };

  const getMenu = () => {
    if (localStorage.getItem("isLogin") == "true") {
      var data = JSON.stringify({
        region_id: localStorage.getItem("access_region"),
        ip_region: "",
      });
    } else {
      var data = JSON.stringify({
        region_id: "",
        ip_region: userCountry,
      });
    }
    if (
      userCountry != "" ||
      (localStorage.getItem("isLogin") == "true" &&
        localStorage.getItem("region") != "")
    ) {
      try {
        axios
          .post(window.base_url + "user/class/subject_list", data, {
            headers: window.header,
          })
          .then(function (response) {
            if (response?.data.status == -1) {
            }
            if (response?.data.status == 1) {
              setSubjects(response?.data?.data);
            }
            return JSON.stringify(response);
          })
          .then(function (parsedData) {})
          .catch((error) => {
            console.log(error);
          });
      } catch (e) {
        console.error(e);
        console.log("error occured in api");
      }
    }
  };

  useEffect(() => {
    getMenu();
  }, [userCountry]);

  const location = useLocation();

  var hours = 1;

  // Session logout timeout

  const [isTimeout, setIsTimeout] = useState(false);

  useEffect(() => {
    const timer = new IdleTimer({
      timeout: 300, //expire after 5 min
      onTimeout: () => {
        setIsTimeout(true);
      },
      onExpired: () => {
        setIsTimeout(true);
      },
    });
    return () => {
      timer.cleanUp();
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("isLogin") === "true") {
      if (location.pathname !== "/zoom") {
        if (isTimeout) {
          logout();
        }
      }
    }
  }, [isTimeout, location]);

  const isMobile = window.innerWidth <= `1000px`; // changed from 700 to 1000 for responsive fix - vishnudas

  const parentlogin = localStorage.getItem("parentLoginNew");
  const studentlogin = localStorage.getItem("studentloginNew");

  const LogOutfunction = () => {
    localStorage.removeItem("parentLoginNew");
    localStorage.removeItem("studentloginNew");
    window.location.href = "/";
  };

  return (
    <div className="landing_div" id="Navbar_div">
      {/* Added landing_div to  */}
      <section class="top-nav">
        {parentlogin === "true" ? (
          <Link to={`/dashboard`} className="nav-text">
            <div className="logo_div_header">
              <img src={logo} height={60} width={60} />
              <span className="lms-head">M2</span>
              <span className="lms-head1">LMS++</span>
            </div>
          </Link>
        ) : studentlogin === "true" ? (
          <Link to={`/student/dashboard`} className="nav-text">
            <div className="logo_div_header">
              <img src={logo} height={60} width={60} />

              <span className="lms-head">M2</span>
              <span className="lms-head1">LMS++</span>
            </div>
          </Link>
        ) : (
          <Link to={`/`} className="nav-text">
            <div className="logo_div_header">
              <img src={logo} height={60} width={60} />

              <span className="lms-head">M2</span>
              <span className="lms-head1">LMS++</span>
            </div>
          </Link>
        )}

        <input id="menu-toggle" type="checkbox" />
        <label class="menu-button-container" for="menu-toggle">
          <div class="menu-button"></div>
        </label>

        <ul className="menu">
          {parentlogin === "true" ? (
            <>
              <li>
                <Link to={`/dashboard`} className="nav-text">
                  Dashboard
                </Link>
              </li>
              <li>
                <Link to={`/profile`} className="nav-text">
                  Profile
                </Link>
              </li>
              <li>
                <Link to={`/parent_payments`} className="nav-text">
                  Payments
                </Link>
              </li>
            </>
          ) : studentlogin === "true" ? (
            <>
              <li>
                <Link to={`/student/dashboard`} className="nav-text">
                  Dashboard
                </Link>
              </li>
              <li>
                <Link to={`/student_profile`} className="nav-text">
                  Profile
                </Link>
              </li>
              <li>
                <Link to={`/topic`} className="nav-text">
                  Topics
                </Link>
              </li>
              <li>
                <Link to={`/notification`} className="nav-text">
                  Notification
                </Link>
              </li>
            </>
          ) : (
            <li>
              <Link to={`/`} className="nav-text">
                Home
              </Link>
            </li>
          )}

          {parentlogin !== "true" && studentlogin !== "true" && (
            <>
              <li>
                <Link to={`/about`} className="nav-text">
                  About Us
                </Link>
              </li>
              <li>
                <Link to={`/courses`} className="nav-text">
                  Courses
                </Link>
              </li>
              <li>
                <Link to={`/Pricing`} className="nav-text">
                  Pricing
                </Link>
              </li>
              <li>
                <Link to={`/ContactUs`} className="nav-text">
                  Contact Us
                </Link>
              </li>
            </>
          )}
        </ul>
        {/* Login button added as a seperate list for responsive issue fix - Vishnudas */}
        <ul className="menu login_button">
          {parentlogin === "true" || studentlogin === "true" ? (
            <li>
              <Button
                style={{
                  backgroundColor: "rgba(71, 133, 255, 1)",
                  borderColor: "rgba(71, 133, 255, 1)",
                  gap: "12.04px",
                  borderRadius: "6.02px",
                  padding: "9.03px, 18.05px, 9.03px, 18.05px",
                  display: "flex",
                  alignItems: "flex-end",
                }}
                onClick={LogOutfunction}
              >
                <Add style={{ color: "white" }} />{" "}
                <span
                  className="nav-text"
                  style={{
                    color: "white",
                    paddingLeft: "2px",
                    paddingRight: "10px",
                  }}
                >
                  Log Out
                </span>
              </Button>
            </li>
          ) : (
            <li>
              <Button
                style={{
                  backgroundColor: "rgba(71, 133, 255, 1)",
                  borderColor: "rgba(71, 133, 255, 1)",
                  gap: "12.04px",
                  borderRadius: "6.02px",
                  padding: "9.03px, 18.05px, 9.03px, 18.05px",
                  display: "flex",
                  alignItems: "flex-end",
                }}
                onClick={handleLoginShow}
              >
                <Add style={{ color: "white" }} />{" "}
                <span
                  className="nav-text"
                  style={{
                    color: "white",
                    paddingLeft: "2px",
                    paddingRight: "10px",
                  }}
                >
                  {" "}
                  Login
                </span>
              </Button>
            </li>
          )}
        </ul>
        {/* Login button added as a seperate list for responsive issue fix - Vishnudas */}
        <Logindialog
          handleLoginClose={handleLoginClose}
          showLogin={showLogin}
          setLoginShow={setLoginShow}
          showlogin2={showlogin2}
          setshowlogin2={setshowlogin2}
        />
      </section>
    </div>
  );
}

export default NewHeader;
