import loader from "../assets/images/loader.gif";
const Loading = () => {

  return (
    <div style={{width:'100%', height:'70vh', backgroundColor:'##ababab85'}} >
    <div className="loader" id="loader">
      <img src={loader}></img>
    </div>
    </div>
  );
};

export default Loading;
