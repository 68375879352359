import React, { useState, useEffect, Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HeaderSection from "./Components/common/header";
// import Header from "./common/header1"; // old footer component commented - vis
import NewHeader from "./Components/common/NewHeader"; // new header component imported - vis
// import Footer from "./common/footer"; // old footer component - vis
import NewFooter from "./Components/common/NewFooter"; // new footer component imported - vis
import { createBrowserHistory } from "history";
import { logout } from "./misc/logout";
import { useCookies } from "react-cookie";
import axios from "axios";
import { stores } from "./Components/Store/Store";
import "./Components/home/NewDesign.css";
import Loading from "./Components/common/Loading";
import ScrollToTop from "./Components/common/ScrollToTop";
import useFetchUserCountry from "./Components/common/useFetchUserCountry";
import { APIDataList } from "./APIDataList";

const Courses = lazy(() => import("./Components/common/Courses"));
const CourseDetailed = lazy(() => import("./Components/common/CourseDetailed"));
const Pricing = lazy(() => import("./Components/common/Pricing/Pricing"));
const Help = lazy(() => import("./Components/common/Contact/help"));
const Privacy = lazy(() => import("./Components/terms/privacy"));
const Terms = lazy(() => import("./Components/terms/terms"));
const HomeContent = lazy(() => import("./Components/home/index"));
const Subscription = lazy(() => import("./Components/submenu/subscription"));
const Liveclass_details = lazy(() =>import("./Components/liveclass/liveclass_details"));
const BlockedIp = lazy(() => import("./misc/BlockedIp"));
const Stripe_status_success = lazy(() =>import("./misc/stripe_status_success"));
const Stripe_status_failed = lazy(() => import("./misc/stripe_status_failed"));
const Link_register = lazy(() => import("./Components/school/link_register"));
const Reset_password = lazy(() => import("./misc/resetpassword"));
const Reset_passcode = lazy(() => import("./misc/resetpasscode"));
const Parent_payments_details = lazy(() =>import("./Components/payments/parent_payments"));
const Instructor_payment_details = lazy(() =>import("./Components/payments/instructor_payments"));
const Teach = lazy(() => import("./misc/teach"));
const Sub_checkout = lazy(() =>import("./Components/submenu/subscription_checkout"));
const Account_Validate = lazy(() => import("./misc/account_validate"));
const Errorpage = lazy(() => import("./misc/404"));
const Registerhere = lazy(() =>import("./Components/registration/registerhere"));
const InstructorRegistration = lazy(() =>import("./Components/registration/instructor-registration"));
const Start_livesession = lazy(() =>import("./Components/liveclass/start_live_session"));
const About = lazy(() => import("./Components/about/About"));
const Filterheader = lazy(() => import("./Components/classes/common"));
const SupportTicket = lazy(() => import("./Components/submenu/supportticket"));
const Ticket_details = lazy(() =>import("./Components/submenu/support_ticket_details"));
const StudentsList = lazy(() => import("./Components/submenu/studentslist"));
const SchoolHome = lazy(() => import("./Components/school/home"));
const Checkout = lazy(() => import("./misc/checkout"));
const Notification = lazy(() => import("./Components/submenu/notification"));
const Report = lazy(() => import("./Components/submenu/report"));
const Feedback = lazy(() => import("./Components/help/feedback"));
const Interactive = lazy(() => import("./misc/interactive"));
const LiveClass = lazy(() => import("./Components/liveclass/liveclass"));
const SupportPage = lazy(() => import("./Components/Support/SupportPages"));
const RecordedClass = lazy(() =>import("./Components/recordedclass/recordedclass"));
const Instructor_profile = lazy(() => import("./Components/submenu/instru_profile"));
const Zoomtest = lazy(() => import("./misc/zoomtest"));
const SchoolHomePage = lazy(() => import("./Components/school/homepage"));
const School_liveclass = lazy(() => import("./Components/liveclass/school_liveclass"));
const StudentProfile = lazy(() => import("./Components/submenu/student_profile"));
const StudentDashboard = lazy(() => import("./Components/student/dashboard"));
const Add_live_session = lazy(() =>import("./Components/liveclass/add_live_session"));
const Dashboard = lazy(() => import("./Components/submenu/dashboard"));
const ParentDashboard = lazy(() => import("./Components/submenu/NewDashboard")); // New Dashboard - najma, 07,06,24
const TopicMobile = lazy(() => import("./Components/topics/topicMobile"));
const Topic = lazy(() => import("./Components/topics/topic"));
const Profile = lazy(() => import("./Components/submenu/profile")); // parent?
const Calendar = lazy(() => import("./Components/submenu/calender"));
const BlogList = lazy(() => import("./Components/blog/BlogList"));
const BlogDetails = lazy(() => import("./Components/blog/BlogDetails"));

const history = createBrowserHistory();

require("dotenv").config()

function App() {
  const { userCountry, ipAddress, loading } = useFetchUserCountry();
  const [cookies, setCookie] = useCookies(["isLogin"]);
  const [isBadIp, setIsBadIp] = useState(false);

  const handleClick = (event) => {
    localStorage.setItem("login", true)
  }
    
  useEffect(() => {
    APIDataList();
    if (localStorage.getItem("isLogin") === "true" && cookies.isLogin !== "true") {
      logout();
    }
  }, [])

  const checkBadIp = async () => {
    const response = await axios.get(`${window.base_url}common/check-bad-ip`, {
      headers: window.header,
    });
    if (response?.data?.data?.status === 1) {
      setIsBadIp(false);
    } else {
      setIsBadIp(true);
    }
  }

  return (
    <BrowserRouter>
      <Suspense fallback={Loading}>
        <NewHeader userCountry={userCountry} />
          <Routes>
            <Route path="/" element={<HomeContent userCountry={userCountry} />} />
            <Route path="/register-here" element={<Registerhere />} />

            <Route path="/support/:footerLinkUrlSlug?" element={<SupportPage />}/>
            <Route path="/about-us/:footerLinkUrlSlug?" element={<SupportPage />}/>

            <Route path="/liveclass" element={<LiveClass />} />
            <Route path="/recordedclass" element={<RecordedClass />} />
            <Route path="/digitallearning/:subject?/:class?/:topic?/:subtopic?" element={<Topic />}/>
            <Route path="/digitallearningapp/:subject?/:class?/:contentType?/:subType?/:topic?/:subtopic?" element={<TopicMobile />}/>
      
            <Route path="/help" element={<Help />} />
            <Route path="/about" element={<About />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />

            <Route path="/classes" element={<Filterheader />} />

            <Route path="/profile" element={<Profile />} />
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/calendar" element={<Calendar />} />

            {/* Added 2 new routes - 05-06-24 - vis */}

            <Route path="/courses" element={<Courses />} />
            <Route path="/courseDetailed/:id" element={<CourseDetailed />} />

            {/* Added 2 new routes - 05-06-24 - vis */}

            <Route path="/Pricing" element={<Pricing />} />
            <Route path="/ContactUs" element={<Help />} />
            <Route path="/topic" element={<Topic />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="/ticket" element={<SupportTicket />} />
            {/* <Route path="/dashboard" element={<Dashboard/>} /> */}
            <Route path="/dashboard" element={<ParentDashboard />} />
            {/*  New parent Dashboard - najma*/}
            <Route path="/report" element={<Report />} />
            <Route path="/instructor/profile" element={<Instructor_profile />} />
            <Route path="/instructor-reg" element={<InstructorRegistration />} />
            <Route path="/instructor_payments" element={<Instructor_payment_details />}/>
            
            <Route path="/school" element={<SchoolHome />} />
            <Route path="/school/setpassword/:id" element={<SchoolHomePage />} />
            <Route path="/school/:school" element={<SchoolHomePage />} />
            <Route path="/setpassword/:id" element={<SchoolHomePage />} />
            <Route path="/school/student/:id" element={<SchoolHomePage />} />
            <Route path="/school/instructor/:id" element={<SchoolHomePage />} />
            <Route path="/school_liveclass" element={<School_liveclass />} />
            <Route path="/school/student_register/:linkType/:id" element={<Link_register />}/>
            <Route path="/school/instructor_register/:id" element={<Link_register />}/>

            <Route path="/student/dashboard" element={<StudentDashboard />} />
            <Route path="/student_profile" element={<StudentProfile />} />
            <Route path="/students" element={<StudentsList />} />

            <Route path="/teach" element={<Teach />} />

            <Route path="/add_liveclass" element={<Add_live_session />} />
            <Route path="/liveclass_details/:id" element={<Liveclass_details />}/>
            <Route path="/start_live_session/:id" element={<Start_livesession />}/>

            <Route path="/account_activate/:id" element={<Account_Validate />} />

            <Route path="/reset_password_form/:id" element={<Reset_password />} />
            <Route path="/reset_passcode_form/:id" element={<Reset_passcode />} />

            <Route path="/checkout" element={<Checkout />} />
            <Route path="/subcription-checkout" element={<Sub_checkout />} />
            <Route path="/zoom" element={<Zoomtest />} />
            <Route path="/interactive" element={<Interactive />} />
            
            {/* <Route path="/ticket_details" element={<Errorpage/>}/>  */}

            <Route path="/ticket_details/:id" element={<Ticket_details />} />
            <Route path="/parent_payments" element={<Parent_payments_details />}/>
            <Route path="/stripe/return/:id" element={<Stripe_status_success />}/>
            <Route path="/stripe/reauth/:id" element={<Stripe_status_failed />} />
            <Route path="/blog" element={<BlogList />} />
            <Route path="/blog/:id" element={<BlogDetails />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/*" element={<Errorpage />} />
          </Routes>
        <NewFooter/>
      </Suspense>
    </BrowserRouter>
  );
}

//   if (localStorage.getItem("login") == "true") {
//     return (
//       <div>
//         <BrowserRouter>
//           {!isBadIp ? (
//             <>
//               <Suspense fallback={Loading}>
//                <NewHeader userCountry={userCountry} />
//                     <Routes>
//                       <Route path="/" element={<HomeContent userCountry={userCountry} />}/>
//                       <Route path="/support/:footerLinkUrlSlug?" element={<SupportPage/>}/>
//                       <Route path="/about-us/:footerLinkUrlSlug?" element={<SupportPage/>}/>

//                       <Route path="/liveclass" element={<LiveClass/>} />
//                       <Route path="/recordedclass" element={<RecordedClass/>}/>

//                       <Route path="/digitallearning/:subject?/:class?/:topic?/:subtopic?" element={<Topic/>}/>
//                       <Route path="/digitallearningapp/:subject?/:class?/:contentType?/:subType?/:topic?/:subtopic?" element={<TopicMobile/>}/>

//                       <Route path="/classes" element={<Filterheader/>} />

//                       <Route path="/help" element={<Help/>} />
//                       <Route path="/about" element={<About/>} />

//                       <Route path="/privacy" element={<Privacy/>} />
//                       <Route path="/terms" element={<Terms/>} />

//                       <Route path="/profile" element={<Profile/>} />
//                       <Route path="/subscription" element={<Subscription/>}/>
//                       <Route path="/calendar" element={<Calendar/>} />

//                       {/* Added 2 new routes - 05-06-24 - vis */}
//                       <Route path="/courses" element={<Courses/>} />
//                       <Route path="/courseDetailed/:id" element={<CourseDetailed/>} />
//                       {/* Added 2 new routes - 05-06-24 - vis */}

//                       <Route path="/Pricing" element={<Pricing/>}/>

//                       <Route path='/ContactUs' element={<Help/>}/>

//                       <Route path="/topic" element={<Topic/>} />
//                       <Route path="/notification" element={<Notification/>}/>
//                       <Route path="/ticket" element={<SupportTicket/>} />

//                       {/* <Route   path="/dashboard" element={Dashboard} /> */}

//                       <Route path="/dashboard" element={ <ParentDashboard/>} />  {/*  New parent Dashboard - najma*/}

//                       <Route path="/report" element={<Report/>} />

//                       <Route path="/instructor/profile" element={<Instructor_profile/>}/>
//                       <Route path="/instructor-reg" element={<InstructorRegistration/>} />
//                       <Route path="/instructor_payments" element={<Instructor_payment_details/>}/>

//                       <Route path="/school" element={<SchoolHome/>} />
//                       <Route path="/school/setpassword/:id" element={<SchoolHomePage/>}/>
//                       <Route path="/school/:school" element={<SchoolHomePage/>}/>
//                       <Route path="/setpassword/:id" element={<SchoolHomePage/>}/>

//                       <Route path="/school/student/:id" element={<SchoolHomePage/>}/>
//                       <Route path="/school/instructor/:id" element={<SchoolHomePage/>}/>
//                       <Route path="/school_liveclass" element={<School_liveclass/>}/>

//                       <Route path="/school/student_register/:linkType/:id" element={<Link_register/>}/>
//                       <Route path="/school/instructor_register/:id" element={<Link_register/>}/>

//                       <Route path="/student/dashboard" element={<StudentDashboard/>}/>
//                       <Route path="/student_profile" element={<StudentProfile/>}/>
//                       <Route path="/students" element={<StudentsList/>} />

//                       <Route path="/teach" element={<Teach/>} />
//                       <Route path="/add_liveclass" element={<Add_live_session/>}/>

//                       <Route path="/liveclass_details/:id" element={<Liveclass_details/>}/>
//                       <Route path="/start_live_session/:id" element={<Start_livesession/>}/>

//                       <Route path="/register-here" element={<Registerhere/>}/>

//                       <Route path="/account_activate/:id" element={<Account_Validate/>}/>

//                       <Route path="/reset_password_form/:id" element={<Reset_password/>}/>
//                       <Route path="/reset_passcode_form/:id" element={<Reset_passcode/>}/>

//                       <Route path="/checkout" element={<Checkout/>} />
//                       <Route path="/subcription-checkout" element={<Sub_checkout/>}/>

//                       <Route path="/zoom" element={<Zoomtest/>} />
//                       <Route path="/interactive" element={<Interactive/>}/>

//                       {/* <Route path="/ticket_details" element={<Errorpage/>}/>  */}
//                       <Route path="/ticket_details/:id" element={<Ticket_details/>}/>

//                       <Route path="/parent_payments" element={<Parent_payments_details/>}/>

//                       <Route path="/stripe/return/:id" element={<Stripe_status_success/>}/>
//                       <Route path="/stripe/reauth/:id" element={<Stripe_status_failed/>}/>

//                       <Route path="/blog" element={<BlogList/>} />
//                       <Route path="/blog/:id" element={<BlogDetails/>} />

//                       <Route path="/feedback" element={<Feedback/>} />

//                       <Route path="/*" element={<Errorpage/>} />
//                     </Routes>
//                 <NewFooter/>
//               </Suspense>
//             </>
//           ) : (
//             <BlockedIp />
//           )}
//         </BrowserRouter>
//       </div>
//     );
//   } else {
//     return (
//       <div>
//         <BrowserRouter>
//           <Suspense fallback={Loading}>
//             <Routes>
//               <Route
//                 path="/digitallearningapp/:subject?/:class?/:contentType?/:subType?/:topic?/:subtopic?"
//                 element={TopicMobile}
//               />
//               <Route path="*">{handleClick}</Route>
//             </Routes>
//           </Suspense>
//         </BrowserRouter>
//       </div>
//     );
//   }
// }

export default App;
