import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import phoneLogo from "../assets/newImages/Vector(12).png";
import icon1 from "../assets/newImages/Icon 1.png";
import icon2 from "../assets/newImages/Icon 2.png";
import icon3 from "../assets/newImages/Icon 3.png";
import icon4 from "../assets/newImages/Icon 4.png";
import footerlogo from "../assets/newImages/simple-logo--illustration--lms--elearning-platform--education--minimalist 1.png";
import "./NewFooter.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function NewFooter() {
  const [socialLinks, setSocialLinks] = useState({});

  const getdata = () => {
    try {
      axios
        .get(window.base_url + "/settings/get-social-links", {
          headers: window.header,
        })
        .then(function (response) {
          if (response?.data.status == -1) {
          }
          if (response?.data.status == 1) {
            setSocialLinks(response?.data?.data.data);
          }
          return JSON.stringify(response);
        })
        .then(function (parsedData) {})
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.error(e);
      console.log("error occured in api");
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  const parentlogin = localStorage.getItem("parentLoginNew");
  const studentlogin = localStorage.getItem("studentloginNew");
  
  const LogOutfunction = () => {
    localStorage.removeItem("parentLoginNew");
    localStorage.removeItem("studentloginNew");
    window.location.href = "/";
  };

  return (
    <div className="Footer_div">
      <div className="landing_div">
        <footer className="newFooter">
          <div class="content">
            <div class="top">
              <div class="logo-details">
                <i class="fab fa-slack"></i>

                {parentlogin === "true" ? (
                  <Link to={`/dashboard`} class="logo_name" >
                    <img src={footerlogo} /> M2
                    <span style={{ color: "#4785FF" }}>LMS++</span>
                  </Link>
                ) : studentlogin === "true" ? (
                  <Link to={`/student/dashboard`} class="logo_name" >
                    <img src={footerlogo} /> M2
                    <span style={{ color: "#4785FF" }}>LMS++</span>
                  </Link>
                ) : (
                  <Link to={`/`} class="logo_name" >
                    <img src={footerlogo} /> M2
                    <span style={{ color: "#4785FF" }}>LMS++</span>
                  </Link>
                )}
              </div>
            </div>
            <div class="link-boxes">
              <ul class="box" id="link_box_1">
                <li>
                  Feel free to call us in working hours Mon - Fri 8:00 - 16.00.
                  <br />
                  Our team will be happy to help answer your queries
                </li>
                <li className="link_name link_spacing">
                  <img src={phoneLogo} />{" "}
                  <span id="link_name_phone">+1 (333) 000-0000</span>
                </li>
                <li className="link_name link_spacing">
                  <a
                    href={`https://${
                      socialLinks.facebook ? socialLinks.facebook.value : "#"
                    }`}
                  >
                    <img src={icon1} />
                  </a>
                  {"  "}
                  <a
                    href={`https://${
                      socialLinks.twitter ? socialLinks.twitter.value : "#"
                    }`}
                  >
                    <img src={icon2} />
                  </a>
                  {"  "}
                  <a
                    href={`https://${
                      socialLinks.youtube ? socialLinks.youtube.value : "#"
                    }`}
                  >
                    <img src={icon3} />
                  </a>
                  {"  "}
                  <a
                    href={`https://${
                      socialLinks.instagram ? socialLinks.instagram.value : "#"
                    }`}
                  >
                    <img src={icon4} />
                  </a>
                </li>
              </ul>
              <ul class="box">
                <li class="link_name">Company</li>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/courses">Courses</Link>
                </li>
                <li>
                  <Link to="/Pricing">Pricing</Link>
                </li>
              </ul>
              <ul class="box">
                <li class="link_name">Courses</li>
                <li>
                  <Link to="#">Course 1</Link>
                </li>
                <li>
                  <Link to="#">Course 2</Link>
                </li>
                <li>
                  <Link to="#">Course 3</Link>
                </li>
                <li>
                  <Link to="#">Course 4</Link>
                </li>
              </ul>
              <ul class="box input-box">
                <li class="link_name">Subscribe To Newsletter</li>
                <li>
                  <input type="text" placeholder="Enter your email" />
                </li>
                <li>
                  <button className="footer_subscribe_button">Subscribe</button>
                </li>
              </ul>
            </div>
          </div>
          <div class="bottom-details">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Item>
                    <span class="copyright_text">
                      Copyright © 2024 M2LMS++ | All Rights Reserved
                    </span>
                  </Item>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Item>
                    <span class="policy_terms">
                      <Link to="/terms">Terms of Use</Link> |{" "}
                      <Link to="/privacy">Privacy Policy</Link>
                    </span>
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default NewFooter;
